<template>
    <!--START SHARE-->
        <section class="section section-lg bg-share">
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h2 class="text-white">SPREAD THE WORD!!</h2>
                        <p class="padding-t-15 home-desc">
                            Share the Facebook event
                        </p>
                        <a href="https://facebook.com/sharer.php?u=https://www.facebook.com/events/1872282009959809" target="_blank" class="btn fb-share btn-bg-white margin-t-30 waves-effect waves-light mb-5">
                            Share
                        </a>
                    </div>
                </div>
            </div>
            <div class="bg-pattern-effect">
                <img src="images/bg-pattern.png" alt="">
            </div>
        </section>
        
        <!--END SHARE-->
</template>

<script>
"use strict";
export default {
  name: 'ShareSection',
  props: {
  }
}
</script>
