<template>
    <!--START SOCIAL-->
        <section class="cta bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <ul class="list-inline social margin-t-20">
                            <li class="list-inline-item"><a href="https://www.facebook.com/events/1872282009959809" target="_blank" class="social-icon"><i class="mdi mdi-facebook"></i></a></li>
                            <!-- <li class="list-inline-item"><a href="https://www.facebook.com/movimentgraffitti" target="_blank" class="social-icon"><i class="mdi mdi-facebook"></i></a></li> -->
                        </ul>
                    </div>
                    <div class="col-lg-4 margin-t-30">
                        <p class="margin-b-0 contact-title"><i class="pe-7s-paper-plane"></i> #ilbankinitaċċittadini</p>
                    </div>
                    <div class="col-lg-4 margin-t-30 text-right">
                        <p class="contact-title"><i class="pe-7s-mail-open"></i>&nbsp; <a href="mailto:info@movimentgraffitti.org">info@movimentgraffitti.org</a></p>
                    </div>
                </div>
            </div>
        </section>
        <!--END SOCIAL-->
</template>

<script>
"use strict";
export default {
  name: 'Social',
  props: {
  }
}
</script>
