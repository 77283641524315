<template>
    <!--START PROTEST INFO-->
        <section class="section bg-light scroll-select" id="events">
            <div class="container">
                <div class="row vertical-content">
                    <div class="col-lg-5">
                        <div class="features-box">
                            <h3>Il-Bankini taċ-Ċittadini!</h3>
                            <h4>Protest March in Valletta</h4>
                            <ul class="text-gray list-unstyled margin-t-30 features-item-list">
                                <li class=""> Date:  <strong>Sunday 22nd September 2024</strong></li>
                                <li class=""> Where: <strong>Merchants Street, Valletta</strong></li>
                                <li class=""> Time:  <strong>12:15 p.m.</strong></li>
                                <!-- <li class="">What to bring: <strong>Placards</strong></li> -->
                            </ul>
                            <a href="https://www.facebook.com/events/1872282009959809" class="btn btn-custom margin-t-30 waves-effect waves-light" target="_blank">
                                Facebook Event <i class="mdi mdi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="features-img features-right text-right">
                            <img src="images/cover-photo-valletta-22sep.png" alt="il-bankini-tac-cittadini-valletta-protest-banner" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--END PROTEST INFO-->
</template>

<script>
export default {
  name: 'ProtestInfo',
  props: {
  }
}
</script>
