<template>
    <div class="team-box text-center hover-effect">
        <div class="team-wrapper">
            <div class="team-member">
                <a v-if="image.source" :href="image.link" target="_blank">
                    <img v-if="image.source" :alt="name + ' logo'" :src="image.source" class="img-fluid rounded">
                </a>

                <h3 v-if="!image.source">
                    <a v-if="link" :href="link" target="_blank">
                        {{ secondName }}
                    </a>
                    <span v-if="!link" >
                        {{ secondName }}
                    </span>
                </h3>
            </div>
        </div>
        <h4 class="team-name">{{ name }}</h4>
        <p v-if="image.source" class="text-uppercase team-designation">{{ secondName }}</p>
    </div>
</template>

<script>
"use strict";

import {Image} from "../../models/Image";

export default {
    name: 'Endorsement',
    props: {
          name: {
              type: String,
              default: '',
          },
          secondName: {
              type: String,
              default: '',
          },
          link: {
              type: String,
              default: '',
          },
          image: {
              type: Image,
              default: new Image().defaults(),
          },
    },
}
</script>
